<template>
  <div class="main">
    <div class="operator_select" v-supAdmin>
      <div class="label">运营商</div>
      <el-select
        v-model="id_operator"
        placeholder="请选择"
        class="my_select"
        @change="selectOperator"
      >
        <el-option
          v-for="item in operatorList"
          :key="item.id_operator"
          :label="item.name_operator"
          :value="item.id_operator"
        ></el-option>
      </el-select>
    </div>

    <div class="default_warning">
      <div class="warning_title">
        <div class="title">默认预警</div>
        <div class="btn_box">
          <el-button
            @click="btnClick"
            :type="btnState === 1 ? 'warning' : 'primary'"
            plain
            >{{ btnState === 1 ? '编辑' : '保存' }}</el-button
          >
        </div>
      </div>

      <div class="warning_time">
        <img src="../../../assets/clock.png" />
        <span>00:00 - 24:00</span>
      </div>

      <!-- 分组 输入框 内容 -->
      <div class="group">
        <div class="group_item">
          <div class="tip">水位</div>
          <div class="content">
            <span>小于</span>
            <input
              type="text"
              v-model="wlMin"
              @input="ipt('wlMin')"
              maxlength="2"
              :disabled="btnState === 1"
            />
            <span>%</span>

            <span style="margin-left: 20px;">大于</span>
            <input
              type="text"
              v-model="wlMax"
              @input="ipt('wlMax')"
              maxlength="2"
              :disabled="btnState === 1"
            />
            <span>%</span>
          </div>
        </div>
        <div class="group_item">
          <div class="tip">持续时间</div>
          <div class="content">
            <span>持续</span>
            <input
              type="text"
              v-model="wlDurationTime"
              @input="ipt('wlDurationTime')"
              maxlength="3"
              :disabled="btnState === 1"
            />
            <span>(min)</span>
          </div>
        </div>
      </div>

      <div class="group">
        <div class="group_item">
          <div class="tip">水箱温度</div>
          <div class="content">
            <span>小于</span>
            <input
              type="text"
              v-model="wtMin"
              @input="ipt('wtMin')"
              maxlength="2"
              :disabled="btnState === 1"
            />
            <span>℃</span>

            <span style="margin-left: 20px">大于</span>
            <input
              type="text"
              v-model="wtMax"
              @input="ipt('wtMax')"
              maxlength="2"
              :disabled="btnState === 1"
            />
            <span>℃</span>
          </div>
        </div>
        <div class="group_item">
          <div class="tip">持续时间</div>
          <div class="content">
            <span>持续</span>
            <input
              type="text"
              v-model="wtDurationTime"
              @input="ipt('wtDurationTime')"
              maxlength="3"
              :disabled="btnState === 1"
            />
            <span>(min)</span>
          </div>
        </div>
      </div>

      <div class="group">
        <div class="group_item">
          <div class="tip">回水温度</div>
          <div class="content">
            <span>小于</span>
            <input
              type="text"
              v-model="wbMin"
              @input="ipt('wbMin')"
              maxlength="2"
              :disabled="btnState === 1"
            />
            <span>℃</span>

            <span style="margin-left: 12px">大于</span>
            <input
              type="text"
              v-model="wbMax"
              @input="ipt('wbMax')"
              maxlength="2"
              :disabled="btnState === 1"
            />
            <span>℃</span>
          </div>
        </div>
        <div class="group_item">
          <div class="tip">持续时间</div>
          <div class="content">
            <span>持续</span>
            <input
              type="text"
              v-model="wbDurationTime"
              @input="ipt('wbDurationTime')"
              maxlength="3"
              :disabled="btnState === 1"
            />
            <span>(min)</span>
          </div>
        </div>
      </div>

      <div class="group">
        <div class="group_item">
          <div class="tip">供水电流</div>
          <div class="content">
            <span>小于</span>
            <input
              type="text"
              v-model="wsMin"
              @input="ipt('wsMin')"
              maxlength="4"
              :disabled="btnState === 1"
            />
            <span>A</span>

            <span style="margin-left: 20px">大于</span>
            <input
              type="text"
              v-model="wsMax"
              @input="ipt('wsMax')"
              maxlength="4"
              :disabled="btnState === 1"
            />
            <span>A</span>
          </div>
        </div>
        <div class="group_item">
          <div class="tip">持续时间</div>
          <div class="content">
            <span>持续</span>
            <input
              type="text"
              v-model="wsDurationTime"
              @input="ipt('wsDurationTime')"
              maxlength="3"
              :disabled="btnState === 1"
            />
            <span>(min)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetPreWarning, UpdateWarning, GetOperatorList2 } from '@/api'

export default {
  name: 'default_warning',
  data() {
    return {
      // 角色
      role: '',
      // 运营商
      id_operator: '',
      // 运营商列表
      operatorList: [],
      // 返回的数据id
      id_warn: '',
      // 水位最低 water_level_min
      wlMin: '',
      // 水位最高
      wlMax: '',
      // 回水时间
      wlDurationTime: '',
      // 水箱 最低 温度 water_tank
      wtMin: '',
      // 水箱 最高 温度
      wtMax: '',
      // 水箱持续时间
      wtDurationTime: '',
      // 回水温度 最小 water_back_min
      wbMin: '',
      // 回水温度 最大
      wbMax: '',
      // 回水温度 持续时间
      wbDurationTime: '',
      // 供水 最小温度 water_supply
      wsMin: '',
      // 供水 最大温度
      wsMax: '',
      // 供水持续时间
      wsDurationTime: '',
      // 按钮状态 默认1: 点击后编辑 2: 编辑后保存
      btnState: 1
    }
  },

  methods: {
    // 获取运营商
    async getOperator() {
      const params = {}
      const { ret, data, msg } = await GetOperatorList2(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      if (data.data.length) {
        this.operatorList = data.data
        this.id_operator = data.data[0].id_operator
        this.getDefaultWarn()
      }
    },

    // 选中运营商
    selectOperator() {
      this.btnState = 1
      this.getDefaultWarn()
    },

    // 获取默认预警
    async getDefaultWarn() {
      const params = {
        warn_level: 1
      }
      if (this.role === 100) {
        params.id_operator = this.id_operator
      }
      const { ret, data, msg } = await GetPreWarning(params)
      if (ret !== 0) {
        return this.$message.error(msg || '获取默认预警失败!')
      }
      if (data && data[0]) {
        this.id_warn = data[0].id
        const warnList = data[0].warn_array
        // 赋值水位
        const wlIndex = warnList.findIndex(i => i.type === 1)
        if (wlIndex !== -1) {
          this.wlMin = warnList[wlIndex].min + ''
          this.wlMax = warnList[wlIndex].max + ''
          this.wlDurationTime = warnList[wlIndex].time_duration + ''
        }
        // 赋值水箱温度
        const wtIndex = warnList.findIndex(i => i.type === 2)
        if (wtIndex !== -1) {
          this.wtMin = warnList[wtIndex].min + ''
          this.wtMax = warnList[wtIndex].max + ''
          this.wtDurationTime = warnList[wtIndex].time_duration + ''
        }
        // 赋值回水温度
        const wbIndex = warnList.findIndex(i => i.type === 3)
        if (wbIndex !== -1) {
          this.wbMin = warnList[wbIndex].min + ''
          this.wbMax = warnList[wbIndex].max + ''
          this.wbDurationTime = warnList[wbIndex].time_duration + ''
        }
        // 赋值供水压力
        const wsIndex = warnList.findIndex(i => i.type === 4)
        if (wsIndex !== -1) {
          this.wsMin = warnList[wsIndex].min + ''
          this.wsMax = warnList[wsIndex].max + ''
          this.wsDurationTime = warnList[wsIndex].time_duration + ''
        }
      }
    },

    // 限制只能输入数字
    ipt(key) {
      this[key] = this[key].replace(/[^\d.]/g, '')
    },

    // 按钮被点击
    btnClick() {
      if (this.btnState === 1) {
        this.btnState = 2
      } else {
        this.saveConfig()
      }
    },

    // 保存配置
    async saveConfig() {
      const params = {
        id_warn: this.id_warn
      }
      const list = []
      // 非空判断
      if (
        this.wlMin === '' ||
        this.wlMax === '' ||
        this.wlDurationTime === '' ||
        this.wtMin === '' ||
        this.wtMax === '' ||
        this.wtDurationTime === '' ||
        this.wbMin === '' ||
        this.wbMax === '' ||
        this.wbDurationTime === '' ||
        this.wsMin === '' ||
        this.wsMax === '' ||
        this.wsDurationTime === ''
      ) {
        return this.$message.warning('请完整填写参数!')
      }
      if (Number(this.wlMax) < Number(this.wlMin)) {
        return this.$message.warning('水位范围错误!')
      }
      if (Number(this.wtMax) < Number(this.wtMin)) {
        return this.$message.warning('水箱温度范围错误!')
      }
      if (Number(this.wbMax) < Number(this.wbMin)) {
        return this.$message.warning('回水温度范围错误!')
      }
      if (Number(this.wsMax) < Number(this.wsMin)) {
        return this.$message.warning('供水压力范围错误!')
      }
      list.push({
        type: 1,
        min: this.wlMin / 1,
        max: this.wlMax / 1,
        time_duration: this.wlDurationTime / 1
      })
      list.push({
        type: 2,
        min: this.wtMin / 1,
        max: this.wtMax / 1,
        time_duration: this.wtDurationTime / 1
      })
      list.push({
        type: 3,
        min: this.wbMin / 1,
        max: this.wbMax / 1,
        time_duration: this.wbDurationTime / 1
      })
      list.push({
        type: 4,
        min: this.wsMin / 1,
        max: this.wsMax / 1,
        time_duration: this.wsDurationTime / 1
      })
      params.warn_array = list
      const { ret, data, msg } = await UpdateWarning(params)
      if (ret !== 0) {
        return this.$message.error(msg || '更新默认预警失败')
      }
      this.$message.success('更新默认预警成功')
      this.btnState = 1
    }
  },

  created() {
    this.role = this.$store.getters.role
    if (this.role === 100) {
      this.getOperator()
    } else {
      this.getDefaultWarn()
    }
  }
}
</script>

<style lang="scss" scoped>
.operator_select {
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .label {
    width: 80px;
    text-align: right;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    padding-right: 12px;
  }

  .my_select {
    width: 230px;
  }
}

.default_warning {
  width: 100%;
  min-height: calc(100% - 60px);
  background-color: #fff;
}

.warning_title {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;

  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1b2351;
    padding-left: 24px;
  }
}

.warning_time {
  width: 800px;
  margin: 0 auto;
  height: 24px;
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #f37771;
  }
}

.group {
  padding-bottom: 30px;

  &:nth-child(-n + 4) {
    border-bottom: 2px solid #eff2f7;
  }

  .group_item {
    margin: 30px auto 0;
    width: 600px;
    display: flex;
    align-items: center;

    .tip {
      flex: 0 0 220px;
      font-size: 14px;
      color: #333333;
    }

    .content {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        color: #333333;
        margin-right: 12px;
      }

      input {
        width: 68px;
        height: 32px;
        margin: 0 12px 0 4px;
        border: 1px solid #999;
        outline: none;
        border-radius: 4px;
        box-sizing: border-box;
        padding-left: 8px;
      }

      input[disabled] {
        background-color: #eff2f7;
        cursor: not-allowed;
      }
    }
  }
}
</style>
